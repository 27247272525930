import { ReactNode } from 'react'
import { ParsedContent } from '@/types/global'
import { StaticImageData } from 'next/image'

import IconLists from '@/helpers/IconLists'
import { getImage, parsedContent } from '@/lib/utils'

// invoice-generator images
import invoiceImg from '~/public/assets/images/invoice-generator/invoice.png'
import invoiceViaText from '~/public/assets/images/invoice-generator/invoice-via-text.png'
import invoiceViaEmail from '~/public/assets/images/invoice-generator/invoice-via-email.png'
import collectMoney from '~/public/assets/images/invoice-generator/how-to-collect-money.png'
import editInvoice from '~/public/assets/images/invoice-generator/edit-invoice.png'
import addLogo from '~/public/assets/images/invoice-generator/add-logo.png'
import addStamp from '~/public/assets/images/invoice-generator/add-stamp.png'
import addSignature from '~/public/assets/images/invoice-generator/add-signature.png'

// online-invoice-generator images
import OnlineInvoice from '~/public/assets/images/online-invoice-generator/tab-images/online-invoices.png'
import TextToPay from '~/public/assets/images/online-invoice-generator/tab-images/Text-to-Pay.png'
import PaymentLink from '~/public/assets/images/online-invoice-generator/tab-images/PaymentLink.png'
import CustomInvoice from '~/public/assets/images/online-invoice-generator/tab-images/CustomInvoice.png'

import InvoiceTemplate from '~/public/assets/images/online-invoice-generator/tab-images/InvoiceTemplate.png'

// POS SYSTEM Images
import PosTabThumb1 from '~/public/assets/images/pos-system/hassle-free-sales.png'
import PosTabThumb2 from '~/public/assets/images/pos-system/efficient-inventory.png'
import PosTabThumb3 from '~/public/assets/images/pos-system/supporting-your-staff.png'
import PosTabThumb4 from '~/public/assets/images/pos-system/streamlined-operations.png'

// PayFac Images
import virtualDebitCardImg from '~/public/assets/images/payfac/pfaas-our-solution/virtual-debit-card.png'
import invoiceManagementImg from '~/public/assets/images/payfac/pfaas-our-solution/invoice-management.png'
import seamlessImg from '~/public/assets/images/payfac/pfaas-our-solution/seamless.png'
import securedPaymentImg from '~/public/assets/images/payfac/pfaas-our-solution/secured-payment.png'
import reportingAndAnalyticsImg from '~/public/assets/images/payfac/pfaas-our-solution/reporting-and-analytics.png'

// Free Invoice Creation Images
import FreeInvoiceCreation1 from '~/public/assets/images/free-invoice-creation/solution.png'
import FreeInvoiceCreation2 from '~/public/assets/images/free-invoice-creation/innovation.png'
import FreeInvoiceCreation3 from '~/public/assets/images/free-invoice-creation/customisation.png'
import FreeInvoiceCreation4 from '~/public/assets/images/free-invoice-creation/security.png'
import FreeInvoiceCreation5 from '~/public/assets/images/free-invoice-creation/support.png'

// online-invoice-software
import isw_sign from '~/public/assets/images/online-invoice-software/invoice-software-works/sign.png'
import isw_customizeProfile from '~/public/assets/images/online-invoice-software/invoice-software-works/customize-profile.png'
import isw_createInvoices from '~/public/assets/images/online-invoice-software/invoice-software-works/create-invoices.png'
import isw_addPaymentOptions from '~/public/assets/images/online-invoice-software/invoice-software-works/add-payment-options.png'
import isw_sendInvoices from '~/public/assets/images/online-invoice-software/invoice-software-works/send-invoices.png'

// Free Invoice Creation Images
import OnlineInvoicePayment1 from '~/public/assets/images/online-invoice-payment/startup.png'
import OnlineInvoicePayment2 from '~/public/assets/images/online-invoice-payment/retailer.png'
import OnlineInvoicePayment3 from '~/public/assets/images/online-invoice-payment/consultant.png'
import OnlineInvoicePayment4 from '~/public/assets/images/online-invoice-payment/subscription.png'
import OnlineInvoicePayment5 from '~/public/assets/images/online-invoice-payment/nonprofit.png'

// Online Invoicing Images
import OnlineInvoicing1 from '~/public/assets/images/online-invoicing/integrated.png'
import OnlineInvoicing2 from '~/public/assets/images/online-invoicing/simplify.png'
import OnlineInvoicing3 from '~/public/assets/images/online-invoicing/unified.png'
import OnlineInvoicing4 from '~/public/assets/images/online-invoicing/mobile.png'
import OnlineInvoicing5 from '~/public/assets/images/online-invoicing/secure.png'
import OnlineInvoicing6 from '~/public/assets/images/online-invoicing/support.png'
import OnlineInvoicing7 from '~/public/assets/images/online-invoicing/constant.png'
import OnlineInvoicing8 from '~/public/assets/images/online-invoicing/pricing.png'

// New Homepage Images
import NewHomepage1 from '~/public/assets/images/homepage/v4/business-merchant.svg'
import NewHomepage2 from '~/public/assets/images/homepage/v4/business-finance.svg'
import NewHomepage3 from '~/public/assets/images/homepage/v4/business-platform.svg'

interface TabDetail {
  icon?: string | ReactNode
  title?: ParsedContent
  content?: ParsedContent
  button?: {
    text?: string
    href?: string
  }
}

export type TabContent = {
  value?: number
  altTxt?: string
  detail?: TabDetail[]
  title?: ParsedContent
  img?: StaticImageData
  content?: ParsedContent
  label?: string | ParsedContent
  detail_content?: ParsedContent
}

const divClass =
  'lg:mt-[100px] [&_p]:!text-twilight [&_p]:text-[15px] [&_p]:leading-[22px] lg:[&_p]:text-xl lg:[&_p]:leading-[28px] max-w-[597px]'

export const SECTION_TABS_DATA = Object.freeze({
  ['homepage_new']: [
    {
      label: parsedContent('Merchant Services <br class="hidden xl:block" /> Fees and PCI'),
      detail: [
        {
          title: parsedContent(
            'Merchant Account Fees',
            'text-blue-cyan text-[18px] xl:text-[24px] font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-[16px] xl:text-[20px] font-normal">Navigate the landscape of merchant fees with clarity. One-rate fees offer simplicity with a consistent charge per transaction. Monthly fees provide straightforward billing, while Interchange fees combine a fixed rate with variable interchange costs, tailored to your sales volume and business model. Understanding these can lead to significant savings and smarter financial planning for your business's payment processing needs.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Merchant PCI Compliances',
            'text-blue-cyan text-[18px] xl:text-[24px] font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-[16px] xl:text-[20px] font-normal">Merchant PCI compliance is critical in safeguarding credit card information. Adhering to the Payment Card Industry Data Security Standard (PCI DSS) ensures businesses follow robust security measures to protect cardholder data. This not only fortifies trust with customers but also mitigates the risk of data breaches. Maintaining strict compliance is essential for secure transactions and the overall integrity of a company’s financial transactions.</p>`
          )
        }
      ],
      image: getImage({ src: NewHomepage1, alt: '' })
    },
    {
      label: parsedContent('Finance <br class="hidden xl:block" /> AR & AP Management'),
      detail: [
        {
          title: parsedContent(
            'Account Receivable',
            'text-blue-cyan text-[18px] xl:text-[24px] font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-[16px] xl:text-[20px] font-normal">Accounts Receivable (AR) management is vital for business liquidity. An Online Invoice Platform streamlines this process by allowing businesses to issue and track invoices digitally. Additionally, Text-to-Pay services enhance convenience by enabling customers to pay invoices via SMS, further accelerating payment cycles and improving cash flow. Together, these tools modernize AR operations, ensuring businesses maintain robust financial health.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Account Payable',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Accounts Payable (AP) is crucial for managing business outflows and maintaining vendor relationships. An Invoice Management Platform facilitates this by automating the handling and approval of payable invoices, ensuring accuracy and timeliness. This technology simplifies workflows, reduces human error, and helps businesses meet payment deadlines efficiently. By leveraging such platforms, companies can optimize their financial operations and sustain good supplier relations.</p>
            `
          )
        }
      ],
      image: getImage({ src: NewHomepage2, alt: '' })
    },
    {
      label: parsedContent('Business <br class="hidden xl:block" /> Platforms & Products'),
      detail: [
        {
          title: parsedContent(
            'Financial Business Platforms',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">A Financial Business Platform integrates Accounts Receivable (AR) and Accounts Payable (AP) functionalities to provide a comprehensive solution for managing a company's finances. These platforms streamline financial workflows, ensuring timely invoicing and payments, reducing manual errors, and improving overall efficiency. By automating these crucial financial operations, companies can enhance cash flow management, maintain strong vendor and customer relationships, and gain real-time insights into their financial health.</p>
            `
          )
        },
        {
          title: parsedContent(
            'Financial Business Products',
            'text-blue-cyan text-xl lg:text-2xl font-semibold lg:mb-[5px] mb-[5px]'
          ),
          content: parsedContent(
            `<p class="text-twilight text-base lg:text-lg font-normal">Financial Business Products like Text-to-Pay, Payment Links, E-Check, and ACH transfers offer versatile and efficient solutions for managing transactions. Text-to-Pay allows customers to make payments via SMS, enhancing convenience and speeding up collection times. Payment Links facilitate secure transactions by enabling one-click payments through personalized URLs. E-Checks and ACH transfers provide reliable and cost-effective alternatives to traditional paper checks, reducing processing times and banking fees. Together, these tools streamline payment processes, improve cash flow, and reduce operational overhead, making them invaluable for modern financial management.</p>
            `
          )
        }
      ],
      image: getImage({ src: NewHomepage3, alt: '' })
    }
  ],
  ['retails-pos-system']: [
    {
      label: 'Hassle Free Sales',
      detail_content: parsedContent(`
       <div class="max-w-[650px]">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Hassle Free Sales</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Accept Payments Anywhere:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            With PayToMe, accept payments both in-store and online.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Effortless Returns and Exchanges:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Our system allows you to accept returns at any location and process exchanges seamlessly.
          </p>
        </div>
      </div>`),
      image: getImage({ src: PosTabThumb1, alt: '' })
    },
    {
      label: 'Efficient Inventory',
      detail_content: parsedContent(`
       <div class="">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Efficient Inventory</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Never Run Out of Stock:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Our POS machine keeps you informed with low-stock alerts and even sends automatic purchase orders to your vendors.
          </p>
        </div>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Streamlined Inventory Counts:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Utilize our barcode scanner to update stock counts on the fly. Plus, you can print barcodes with ease.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Flexible Fulfillment Options:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Give your customers the freedom to choose between in-store pickup or convenient shipping for their orders.
          </p>
        </div>
      </div>`),
      image: getImage({ src: PosTabThumb2, alt: '' })
    },
    {
      label: 'Supporting Your Staff',
      detail_content: parsedContent(`
       <div class="">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Supporting Your Staff</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Know Your Customers:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Take notes, send direct messages, and automatically track spending habits.
          </p>
        </div>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Drive Customer Loyalty:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Keep customers coming back with targeted marketing campaigns that promote your latest events, offers, and products.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Efficient Team Management:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            PayToMe.co makes it simple to create schedules, set permissions, and track employee time on a user-friendly POS system.
          </p>
        </div>
      </div>`),
      image: getImage({ src: PosTabThumb3, alt: '' })
    },
    {
      label: 'Streamlined Operations',
      detail_content: parsedContent(`
       <div class="">
        <h2 class="text-[22px] hidden lg:!block lg:text-[32px] leading-[30px] lg:leading-[33px] text-[#020626] font-bold mb-[10px] lg:mb-6">Streamlined Operations</h2>
        <div class='content-item md:mb-5 mb-2'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Seamless Integrations:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
          Connect all your essential tools like QuickBooks and WooCommerce directly into your POS software, consolidating your operations in one place.
          </p>
        </div>
        <div class='content-item'>
          <h3 class='md:text-2xl text-lg text-[#3D4482] font-semibold mb-0 sm:mb-2'>Actionable Insights:</h3>
          <p class='text-twilight md:text-xl text-base max-w-[100%] md:max-w-[657px]'>
            Gain valuable insights into your business with detailed sales, profit, and sell-through reports. Understand what drives your revenue and make informed decisions.
          </p>
        </div>
      </div>`),
      image: getImage({ src: PosTabThumb4, alt: '' })
    }
  ],
  ['online-invoice-generator']: [
    {
      label: 'Online Invoice',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Online Invoice</h2>
        <p class="mb-3 lg:mb-7">PayToMe’s digital invoices offer a modern solution to traditional billing methods.</p>
        <p class="mb-3 lg:mb-4">With our advanced invoice generator, you can create, send, and track invoices electronically, eliminating paper-based inefficiencies</p>
        <p>Save time and money with streamlined invoicing.</p>
      </div>`),
      image: getImage({ src: OnlineInvoice, alt: '' })
    },
    {
      label: 'Text to Pay',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Text to Pay</h2>
        <p class="mb-3 lg:mb-4">Text to Pay is a convenient payment solution that allows customers to settle their bills via SMS. With just a simple text message, your clients can make payments securely and swiftly, enhancing their overall payment experience.</p>
        <p>PayToMe’s patented text-to-pay technology simplifies payments! Just send an invoice link through SMS!</p>
      </div>`),
      image: getImage({ src: TextToPay, alt: '' })
    },
    {
      label: 'Payment Link',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Payment Link</h2>
        <p class="mb-3 lg:mb-4">Payment links are a hassle-free way to request payments from your customers. Text payment link via email or messaging apps, and your clients can make payments with ease, from anywhere and any time!</p>
        <p>PayToMe’s patented text-2-pay technology allows you to send direct payment link to your clients.</p>
      </div>`),
      image: getImage({ src: PaymentLink, alt: '' })
    },
    {
      label: 'Custom Invoice',
      detail_content: parsedContent(`
       <div class="${divClass}">
        <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Custom Invoice</h2>
        <p class="mb-3 lg:mb-4">Our custom invoice templates allow your brand's identity to shine through. Tailor your invoices with personalized designs, incorporating your logo, color scheme, and unique branding elements.</p>
        <p>Explore our custom invoice samples to see how you can make every invoice a reflection of your brand's individuality.</p>
      </div>`),
      image: getImage({ src: CustomInvoice, alt: '' })
    },
    {
      label: 'Invoice Template',
      detail_content: parsedContent(`
       <div class="${divClass}">
          <h2 class="text-[48px] text-[#020626] font-bold mb-4 hidden lg:!block">Invoice Template</h2>
          <p class="mb-3 lg:mb-4">Our diverse selection of invoice templates by country ensures that your invoices comply with local regulations and are culturally appropriate.</p>
          
        </div>`),
      image: getImage({ src: InvoiceTemplate, alt: '' })
    }
  ],
  ['invoice-generator']: [
    {
      label: 'How to Use',
      title: parsedContent('How to Use the PayToMe.co Invoice Generator', 'section-body-title--large'),
      content: parsedContent('Efficient and Convenient Invoicing with PayToMe.co Invoice Generator', 'max-w-[649px]'),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Create a New Invoice'),
          content: parsedContent(
            'Enter all the necessary information, Company details, client details, invoice date, due date, and items.'
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Customize as Needed'),
          content: parsedContent('Add your company logo, stamp, and signature for a personalized touch.')
        },
        {
          icon: IconLists.previewNoteIcon,
          title: parsedContent('Preview Your Invoice'),
          content: parsedContent('Review the invoice to ensure accuracy.')
        },
        {
          icon: IconLists.sendIcon,
          title: parsedContent('Send'),
          content: parsedContent(
            'You can save the invoice for later or send it directly to your client via text message and email.'
          )
        },
        {
          icon: IconLists.collectPaymentIcon,
          title: parsedContent('Collect Payments'),
          content: parsedContent(`<p>
            If desired, add a payment link for easy and secure payment collection. There are three modes of
            collecting payments
          </p>
          <ul class='list-inside my-[16px] lg:my-[28px] [&_*]:text-[#004263]'>
            <li class='list-disc pl-2 py-0.5'>Add Tips</li>
            <li class='list-disc pl-2 py-0.5'>Split Payments</li>
            <li class='list-disc pl-2 py-0.5'>Installment Payment</li>
          </ul>
          <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>
            Using the Invoice Generator is a breeze, simplifying your invoicing process and impressing your clients
            with professional invoices.
          </p>
          `)
        }
      ],
      image: getImage({ src: invoiceImg, alt: '' })
    },
    {
      label: 'Invoice via Text',
      title: parsedContent('How to Send Invoices with Text Messaging', 'section-body-title--large'),
      content: parsedContent('Sending Instant Invoice Link Through Text Messaging'),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Create Your Invoice'),
          content: parsedContent(
            "Use PayToMe's Invoice Generator to craft your invoice with all the necessary details."
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Add Correct Phone'),
          content: parsedContent("Provide your client's phone number. Make sure you are providing correct number.")
        },
        {
          icon: IconLists.cropIcon,
          title: parsedContent('Save Items'),
          content: parsedContent('Save each item after proper entry.')
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Submit'),
          content: parsedContent(
            `Click "Submit" and you're good to go. Your client will receive the invoice as a text message.`
          )
        }
      ],
      image: getImage({ src: invoiceViaText, alt: '' })
    },
    {
      label: 'Invoice via Email',
      title: parsedContent('How to Send Invoices with Emails', 'section-body-title--large'),
      content: parsedContent('Send Professional Invoices with Ease through Emails'),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Generate Your Invoice'),
          content: parsedContent("Start by creating your invoice using PayToMe's Invoice Generator.")
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent("Enter Recipient's Email Address"),
          content: parsedContent(
            `Input your client's email address. You will get this option while adding “Bill/Service To”`
          )
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Submit'),
          content: parsedContent(
            `Click "Submit" and your client will receive the invoice in their email inbox. It's that simple! Efficient invoicing made easy.`
          )
        }
      ],
      image: getImage({ src: invoiceViaEmail, alt: '' }),
      altTxt: 'Send Invoices'
    },
    {
      label: 'Collect Money',
      title: parsedContent('How to Collect Money (Add Payment Link)', 'section-body-title--large'),
      content: parsedContent("Effortless Payment Collection with PayToMe.Co's Payment Link Feature"),
      detail: [
        {
          icon: IconLists.paymentLinkIcon,
          title: parsedContent('Payment Link Option'),
          content: parsedContent(`Look for the "Add Payment Link" option within the generator.`)
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Specify Payment Method'),
          content: parsedContent(
            "Choose the payment method you prefer or that your client prefers, whether it's credit card, debit card, or bank account."
          )
        },
        {
          icon: IconLists.clientReceiveLinkIcon,
          title: parsedContent('Client Receives Link'),
          content: parsedContent('Your client will receive the invoice along with the payment link.')
        },
        {
          icon: IconLists.securePaymentIcon,
          title: parsedContent('Secure Payment'),
          content:
            parsedContent(`<p class="mb-3">Your client can click the link and make a secure payment directly through the given payment link. </p>
        <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>By adding a payment link, you streamline the payment process, making  it convenient for both you and your clients.</p>
        `)
        }
      ],
      image: getImage({ src: collectMoney, alt: 'invoice payment' })
    },
    {
      label: 'Edit Invoice',
      title: parsedContent('Edit Invoice on Invoice Generator', 'section-body-title--large'),
      content: parsedContent("Modify and Update Invoices with PayToMe.Co's Invoice Generator"),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Access Your Account'),
          content: parsedContent('Log in to your PayToMe.co account.')
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Locate Your Invoices'),
          content: parsedContent('Find the invoice you want to edit within your dashboard.')
        },
        {
          icon: IconLists.selectEditOptionIcon,
          title: parsedContent('Select Edit Option'),
          content: parsedContent('Click on the "Edit" or "Modify" option associated with the specific invoice.')
        },
        {
          icon: IconLists.makeChangesIcon,
          title: parsedContent('Make Changes'),
          content: parsedContent('Update the necessary information, such as amounts, descriptions, or client details.')
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent('After making your edits, remember to save the changes before proceeding.')
        },
        {
          icon: IconLists.reviewInvoiceIcon,
          title: parsedContent('Review the Invoice'),
          content: parsedContent('Double-check the revised invoice to ensure accuracy.')
        },
        {
          icon: IconLists.resendIcon,
          title: parsedContent('Resend if Needed'),
          content:
            parsedContent(`<p class="mb-3">If you've already sent the invoice to your client, you can choose to resend the updated version.</p>
        <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>Editing invoices with PayToMe's Invoice Generator is straightforward and allows you to keep your records accurate and up-to-date.</p>
        `)
        }
      ],
      image: getImage({ src: editInvoice, alt: 'online invoice' })
    },
    {
      label: 'Add LOGO',
      title: parsedContent('Add Your LOGO on Invoice Generator', 'section-body-title--large'),
      content: parsedContent('How to Add Your Logo to Your Invoice: Step-by-Step Guide'),
      detail: [
        {
          icon: IconLists.attachIcon,
          title: parsedContent('Upload Your Logo'),
          content: parsedContent(
            'Click the "Upload Logo" button to select and upload your company logo from your device.'
          )
        },
        {
          icon: IconLists.saveChangesIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent("Don't forget to save your changes to ensure your logo appears on future invoices.")
        },
        {
          icon: IconLists.lightModeIcon,
          title: parsedContent('Preview an Invoice'),
          content:
            parsedContent(`<p class="mb-3">Create a new invoice or access an existing one to see your logo elegantly integrated.</p>
        <p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>Adding your logo to your invoices adds a professional touch and reinforces your brand identity, making a lasting impression on your clients.</p>
        `)
        }
      ],
      image: getImage({ src: addLogo, alt: 'logo on Invoice' })
    },
    {
      label: 'Add Stamp',
      title: parsedContent('Add Your Company Stamp on Invoice Generator', 'section-body-title--large'),
      content: parsedContent('Enhance Your Invoices with Your Company Stamp'),
      detail: [
        {
          icon: IconLists.addNoteIcon,
          title: parsedContent('Upload Your Company Stamp'),
          content: parsedContent(
            'Click the "Upload Stamp" or similar button to select and upload an image of your company stamp from your device.'
          )
        },
        {
          icon: IconLists.submitIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent(
            'Make sure to save your settings to ensure your company stamp appears on future invoices.'
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Preview an Invoice'),
          content:
            parsedContent(`<p class="mb-3">Create a new invoice or access an existing one to see your company stamp proudly displayed.</p> 
<p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>Incorporating your company stamp into your invoices adds an extra layer of professionalism and authenticity to your billing documents.</p>`)
        }
      ],
      image: getImage({ src: addStamp, alt: 'Invoice Generator' })
    },
    {
      label: 'Add Signature',
      title: parsedContent('Add Your Signature On Invoice Generator', 'section-body-title--large'),
      content: parsedContent('Personalize Your Invoices with Your Own Signature'),
      detail: [
        {
          icon: IconLists.attachIcon,
          title: parsedContent('Upload Your Signature'),
          content: parsedContent(
            'Click on "Upload Signature" to select and upload an image file of your signature from your device.'
          )
        },
        {
          icon: IconLists.saveChangesIcon,
          title: parsedContent('Save Changes'),
          content: parsedContent(
            'Remember to save your settings to ensure your signature is included on your invoices.'
          )
        },
        {
          icon: IconLists.customizeNoteIcon,
          title: parsedContent('Preview an Invoice'),
          content: parsedContent(
            `<p class="mb-3">Create a new invoice or access an existing one to see your personalized signature elegantly integrated.</p><p class='text-[#697A8D] text-start text-base lg:text-xl leading-6 lg:leading-[30px]'>Adding your signature to your invoices provides a personal touch and authenticity, enhancing your professional image with clients.</p>`
          )
        }
      ],
      image: getImage({ src: addSignature, alt: 'Signature On Invoice' })
    }
  ],
  ['payfac-as-a-service']: [
    {
      label: 'Virtual & Debit Card',
      detail: [
        {
          title: parsedContent(
            'Virtual & Debit Card',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PFaaS includes virtual card and debit card offerings, enabling merchants to issue cards to their customers for seamless online transactions.</p>
            <p>These cards can be used for one-time purchases or as ongoing payment methods, providing flexibility and convenience.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: virtualDebitCardImg, alt: '' })
    },
    {
      label: 'Invoice Management',
      detail: [
        {
          title: parsedContent(
            'Invoice Management',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PayToMe's PFaaS solution offers comprehensive invoice management capabilities. Businesses can easily generate and send invoices to their customers, track payments, and set up automated reminders for overdue invoices.</p>
            <p>This streamlines the billing process and improves cash flow management.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: invoiceManagementImg, alt: '' })
    },
    {
      label: 'Seamless Integration',
      detail: [
        {
          title: parsedContent(
            'Seamless Integration',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PayToMe's PFaaS solution seamlessly integrates with existing business systems and software.</p>
            <p>We offer a range of APIs and developer tools, making it easy for merchants to connect their websites, mobile apps, or point-of-sale (POS) systems with our payment platform.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: seamlessImg, alt: '' })
    },
    {
      label: 'Secured Payment',
      detail: [
        {
          title: parsedContent(
            'Secured Payment',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">Security is a top priority for PayToMe. Our PFaaS solution incorporates robust encryption and data protection measures to ensure the safe handling of sensitive payment information.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: securedPaymentImg, alt: '' })
    },
    {
      label: 'Reporting and Analytics',
      detail: [
        {
          title: parsedContent(
            'Reporting and Analytics',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">PFaaS offers robust reporting and analytics tools, providing payment facilitators with insights into transaction volumes, revenue, and other key metrics. These insights help businesses make data-driven decisions and optimize their payment processes.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: reportingAndAnalyticsImg, alt: '' })
    }
  ],
  ['free-invoice-creator']: [
    {
      label: 'All-In-One Solution',
      detail: [
        {
          title: parsedContent(
            'All-In-One Solution',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">While many platforms offer invoicing alone, PayToMe.co presents a holistic financial ecosystem, encompassing payment facilitation and Software as a Service (SaaS).</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: FreeInvoiceCreation1, alt: '' })
    },
    {
      label: 'AI-Powered Innovation',
      detail: [
        {
          title: parsedContent(
            'AI-Powered Innovation',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">Our cutting-edge AI technology ensures seamless integration, simplifying complex processes and enhancing user experiences.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: FreeInvoiceCreation2, alt: '' })
    },
    {
      label: 'Customization',
      detail: [
        {
          title: parsedContent(
            'Customization',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">We understand that every business is unique. Our platform allows you to tailor invoices to your brand's personality, setting you apart from the crowd.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: FreeInvoiceCreation3, alt: '' })
    },
    {
      label: 'Security',
      detail: [
        {
          title: parsedContent(
            'Security',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">We prioritize your data's security, implementing state-of-the-art measures to safeguard your sensitive information.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: FreeInvoiceCreation4, alt: '' })
    },
    {
      label: 'Responsive Support',
      detail: [
        {
          title: parsedContent(
            'Responsive Support',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-6 mb-[12px]">Have a question? Need assistance? Our dedicated customer support team is always ready to help you navigate the platform with ease.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: FreeInvoiceCreation5, alt: '' })
    }
  ],
  ['online-invoice-software']: [
    {
      label: 'Sign Up',
      detail: [
        {
          title: parsedContent(
            'Sign Up',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Begin by signing up for a PayToMe account. You'll need to provide some basic information about your business, such as your company name, contact details, and preferred payment methods.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: isw_sign, alt: '' })
    },
    {
      label: 'Customize Your Profile',
      detail: [
        {
          title: parsedContent(
            'Customize Your Profile',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Once you're logged in, you can personalize your profile by adding your company logo, choosing your brand colors, and setting up your preferred payment gateways. This step ensures that your invoices reflect your brand identity. Also, this free invoice software offers invoicing templates so that you can choose one as per your preference.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: isw_customizeProfile, alt: '' })
    },
    {
      label: 'Create Invoices',
      detail: [
        {
          title: parsedContent(
            'Create Invoices',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Creating invoices online with PayToMe is a breeze. Simply click on the "Create Invoice" button and enter the necessary details, including the products or services provided, quantities, and prices. The software will automatically calculate the totals for you.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: isw_createInvoices, alt: '' })
    },
    {
      label: 'Add Payment Options',
      detail: [
        {
          title: parsedContent(
            'Add Payment Options',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Incorporate payment options directly into your invoices. PayToMe allows you to insert payment links that lead directly to your chosen payment gateways. This means your clients can conveniently settle their bills with just a few clicks and you get paid faster.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: isw_addPaymentOptions, alt: '' })
    },
    {
      label: 'Send Invoices',
      detail: [
        {
          title: parsedContent(
            'Send Invoices',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Once your invoice is ready, you can send it to your clients via email. PayToMe provides you with the option to include a personalized message and a note about the payment process. You can also choose to send automated reminders for overdue payments.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: isw_sendInvoices, alt: '' })
    }
  ],
  ['online-invoice-payment']: [
    {
      label: 'Small Business or Startup ',
      detail: [
        {
          title: parsedContent(
            'Small Business or Startup ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Small businesses and startups face numerous challenges, including cash flow management. PayToMe's online invoice payment solution helps small business owners automate invoicing, accept online payments, and track their financial health. </p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicePayment1, alt: '' })
    },
    {
      label: 'E-commerce Store or Retailer ',
      detail: [
        {
          title: parsedContent(
            'E-commerce Store or Retailer ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">In the fast-paced world of e-commerce, efficient payment processing is essential for success. PayToMe allows online stores to easily accept payments, track sales, and offer customers convenient payment choices. </p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicePayment2, alt: '' })
    },
    {
      label: 'Service Provider or Consultant ',
      detail: [
        {
          title: parsedContent(
            'Service Provider or Consultant ',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Service-based businesses rely on timely invoicing and payment collection to sustain operations. From consulting fees to project-based services, our platform simplifies financial management for service-oriented businesses.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicePayment3, alt: '' })
    },
    {
      label: 'Subscription-Based Business',
      detail: [
        {
          title: parsedContent(
            'Subscription-Based Business',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Subscription-based businesses thrive on recurring revenue streams. Whether offering software subscriptions, membership services, or subscription boxes, our platform ensures consistent cash flow and customer satisfaction.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicePayment4, alt: '' })
    },
    {
      label: 'Nonprofit Organization',
      detail: [
        {
          title: parsedContent(
            'Nonprofit Organization',
            'text-black-pearl text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Nonprofit organizations take online invoice payment solution to accept online donations, manage donor contributions, and track fundraising efforts effectively. With customizable donation forms and secure payment processing, nonprofits can streamline fundraising campaigns and maximize donor engagement.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicePayment5, alt: '' })
    }
  ],
  ['online-invoicing-software']: [
    {
      label: 'Integrated Platform',
      detail: [
        {
          title: parsedContent(
            'Integrated Platform',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Invoicing is just one piece of the puzzle. Our online invoicing seamlessly integrates with our suite of payment features, creating a unified platform that simplifies your financial operations.</p>
            `,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing1, alt: '' })
    },
    {
      label: 'Simplified Process',
      detail: [
        {
          title: parsedContent(
            'Simplified Process',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Our user-friendly interface is designed for businesses of all sizes. Whether you're a solo entrepreneur or a growing enterprise, managing invoices becomes effortless, allowing you to focus on what truly matters. </p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing2, alt: '' })
    },
    {
      label: 'Unified Dashboard',
      detail: [
        {
          title: parsedContent(
            'Unified Dashboard',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">With PayToMe’s online invoice creator, your financial activities are combined into a single dashboard. No need to toggle between different platforms or applications. Seamlessly access invoices, payment records, and transaction histories all in one place, providing a comprehensive view of your financial health.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing3, alt: '' })
    },
    {
      label: 'Mobile Accessibility',
      detail: [
        {
          title: parsedContent(
            'Mobile Accessibility',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Manage your invoicing on the go. Our platform is accessible from any device with an internet connection. Whether you're at your desk, in a meeting, or on a business trip, you have the flexibility to oversee and manage your invoices anytime, anywhere.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing4, alt: '' })
    },
    {
      label: 'Secure Data Management',
      detail: [
        {
          title: parsedContent(
            'Secure Data Management',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">Data security is non-negotiable. PayToMe uses robust security measures to protect your sensitive financial data. Our encryption protocols, authentication processes, and secure servers ensure that your information remains confidential and secure.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing5, alt: '' })
    },
    {
      label: 'Dedicated Support',
      detail: [
        {
          title: parsedContent(
            'Dedicated Support',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">At PayToMe, you're never alone. Our dedicated support team is here to assist you with any inquiries or challenges you might face. Whether it's technical support, troubleshooting, or guidance on best practices, we're just a message away.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing6, alt: '' })
    },
    {
      label: 'Constant Innovation',
      detail: [
        {
          title: parsedContent(
            'Constant Innovation',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">As technology evolves, so do we. PayToMe remains committed to staying at the forefront of fintech innovation. Expect continuous updates and enhancements to our online invoicing solution, ensuring you're always equipped with the latest tools and features.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing7, alt: '' })
    },
    {
      label: 'Transparent Pricing',
      detail: [
        {
          title: parsedContent(
            'Transparent Pricing',
            'text-blue-cyan text-xl lg:text-[32px] leading-[22px] lg:leading-[normal] font-bold lg:mb-[28px] mb-[12px]'
          ),
          content: parsedContent(
            `<p class="lg:mb-0 mb-[12px]">No hidden fees or surprises. PayToMe offers transparent pricing models that cater to businesses of various sizes. Choose a plan that aligns with your needs, and rest assured that you're getting value for your investment.</p>`,
            'text-twilight'
          )
        }
      ],
      image: getImage({ src: OnlineInvoicing8, alt: '' })
    }
  ]
})
